// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap. Modified to use specific version of bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url(https://code.jquery.com/ui/1.10.4/themes/ui-lightness/jquery-ui.css);
    // Application specific CSS
    // Global
html *
{
    font-family: YuGothic, 'Yu Gothic' !important;
    color: $text-color;
}
h1 {
    font-size: 24px;
}
h2 {
    font-size: 20px;
}
h3 {
    font-size: 24px;
}
a .link-button {
    color: $btn-color-text;
    background-color: $button-color;
}
.red {
    color: red;
}
.white {
    color: white;
}
.no-pad {
    padding: 0;
}
.btn {
    background-color: $button-color;
    color: white;
}
// equal height columns
.equal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-wrap: wrap;
}
.equal > [class*='col-'] {
    display: flex;
    flex-direction: column;
}
// Header
.header-logo {
    height: 50px;
}
.navbar {
    border: none;
    margin-top: 30px;
}
#menu-top-menu a {
    font-size: 15px;
    padding-top: 30px;
    padding-bottom: 0 !important;
}
.patent-pending {
    font-size: 11px;
    display: inline-block;
    margin-left: 15px;
    margin-top: 16px;
    padding: 5px 15px;
    color: white;
    background-color: #ecb31f;
    border: solid $main-color 4px;
    border-radius: 10px;
}
// Homepage
.home-body {
    background: url(/images/background-img.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.home-required, .require {
    color: $required;
}
.ranking {
    .mark-z {
        background-color: $button-color;
        color: $btn-color-text;
        padding: 5px;
    }
    td {
        font-size: larger;
        font-weight: bold;
        padding: 15px;
    }
}
.home-wrap {
    margin-top: 50px;
    margin-bottom: 10px;
    .home-header {
        margin-bottom: 25px;
    }
    h1 {
        font-size: 19px;
    }
    h2 {
        font-size: 15px;
    }
    h3 {
        font-size: 19px;
    }
    p {
        font-size: 14px;
        &.large {
            font-size: 23px;
        }
        &.smaller {
            font-size: 12px;
        }
        &.tiny {
            font-size: 9px;
        }
    }
    .homepage-content {
        margin-bottom: 10px;
    }
    .home-left {
        background-color: $main-color;
        padding: 30px;
        .home-fish {
            width: 50px;
            margin: 0 auto;
        }
        .home-body-logo {
            width: 100%;
            height: 15%;
            margin: 0 auto 20px auto;
        }
        .bar-wrap {
            margin-bottom: 20px;
            .bar {
                position: relative;
                top: -5px;
                display: inline-block;
                margin: 0;
                width: 50px;
                border-top: solid $text-color 1px;
                margin-top: -15px;
            }
            .bar-word {
                display: inline-block;
                margin: 0;
                padding: 0;
                margin-left: 20px;
                margin-right: 20px;
                font-weight: bold;
            }
        }
        .logo-wrap {
            padding: 0;
        }
        .company-name {
            margin-top: 9px;
            font-weight: bold;
        }
        .phone-wrap .large {
            font-weight: bold;
            margin: 0;
        }
        .phone-wrap {
            text-align: right;
        }
        .btn {
            margin-top: 20px;
            margin-bottom: 40px;
            font-size: 14px;
        }
    }
    .home-right {
        background-color: white;
        .form-top {
            margin-bottom: 20px;
        }
        .yellow {
            color: $text_color;
            background-color: $main_color;
        }
        .btn {
            margin-bottom: 15px;
        }
        .home-form {
            margin-bottom: 20px;
            label {
                display: block;
            }
            input[type=text] {
                margin-bottom: 15px;
                width: 100%;
                border-radius: 8px;
                height: 40px;
                -webkit-appearance: none;
                -moz-appearance: none;
                border: 1px solid $text_color;
                padding-left: 5px;
            }
            input[type=file] {
                margin-bottom: 15px;
            }
            select {
                height: 25px;
                margin-bottom: 15px;
            }
        }
        .currency {
            margin-right: 5px;
            font-weight: bold;
        }
        #building_value {
            width: 80%;
            max-width: 300px;
        }
        #floors {
            width: 50px;
        }
        .floors-wrap {
            height: 40px;
            position: relative;
            margin-bottom: 15px;
        }
        .floors-text {
            position: absolute;
            bottom: 0px;
            padding-left: 4px;
        }
        #materials {
            width: 100%;
            max-width: 150px;
        }
        .form-right {
            input {
                width: 100%;
            }
        }
        .form-bottom {
            .agree {
                display: inline-block;
                margin-right: 5px;
            }
            .agree-label {
                display: inline-block;
            }
            .btn {
                display: block;
                width: 250px;
                margin: 0 auto 20px auto;
                border-radius: 8px;
                font-size: 23px;
                font-weight: bold;
                padding: 10px 50px 10px 50px;
                box-shadow: 0px 5px 0px #ccbe00;
            }
        }
		#ers-power-wrap {
			height: 20px;
			#ers-power {
				position: absolute;
				bottom: 0;
				right: 0;
				margin: 10px;
			}
		}
    }
    // Medium devices or larger
    @media (min-width: 992px ) {
		.container {
			width: 100%;
		}
        /*.home-left, .home-right {
            min-height: 750px;
        }*/
        .home-right .form-left {
            border-right: solid $text_color 1px;
        }
    }
}
@media (min-width: 992px ) {
	.home-wrap {
		margin-left: 200px;
		margin-right: 200px;
	}
}
.home-footer {
	font-size: 12px;
}

.home-copyright {
	position: absolute;
	right: 0;
	p {
		width: 215px;
		padding: 0 20px 0 0 !important;
		color: white !important;
	}
}
.standard-body {
    background-color: $footer-light;
    .navbar-default {
        background-color: $footer-light;
    }
    .company-logo img {
        width: 150px;
    }
    .header-wrap > .row {
        border-bottom: solid $text-color 1px;
        margin-bottom: 15px;
    }
    .navbar a {
        margin-right: 0;
        padding-right: 0;
    }
    .page3-row1-wrap {
        padding: 0;
    }
    .threed-file img {
        width: 100%;
    }
    .ipanel {
        margin-bottom: 15px;
        .threed-file {
            background-color: $building-back-color !important;
            h4 {
                color: white;
            }
            .panel-header {
                border-bottom: solid white 1px;
            }
        }
        .panel-wrap {
            padding: 25px;
            border-radius: 15px;
            background-color: white;
        }
        &.panel-left {
            padding: 0;
        }
        &.panel-right {
            padding: 0;
        }
        .panel-header {
            padding-bottom: 5px;
            border-bottom: solid $text-color 1px;
            h4 {
                margin: 0;
                line-height: 1.6;
				display: inline-block;
                span {
                    font-weight: bold;
                    font-size: 45px;
                }
            }
        .building_adress {
            font-size: 15px;
            font-weight: 400;
        }
        }
        .panel-body {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            color: $text-color;
            .label {
                font-size: 14px;
                color: $text-color;
                padding: 0;
                font-weight: normal;
            }
            .stat {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 15px;
            }
            .left-inner { padding-left: 0; pading-right: 0; }
            .right-inner { padding-left: 0; pading-right: 0; }
            .probability {
                font-size: 46px;
            }
            .cost {
                font-size: 46px;
            }
            #page2-guide {
                background-color: $main-color;
            }
            .map {
                width: 100%;
                height: 500px;
            }
            .building {
				height: 520px;
                position: relative;
                .building-img-text {
                    top: 7em;
                    width: 100%;
                    text-align: center;
                    color: black;
                    font-weight: bold;
                }
            }
        }
        .panel-footer {
            background-color: $panel-default-heading-bg;
            border-top: 0px;
            padding: 15px 0px;
            p {
                font-size: smaller;
                padding-left: 5px;
            }
        }
    }
    .bottom-lines {
        text-align: center;
        #page2-footer-message {
            background-color: $main-color;
        }
    }
    .buttons {
        margin-top: 15px;
        // margin-bottom: 15px;

        button, -wrap button:hover, -wrap button:visited {
            display: inline-block;
            background-color: #009CE0;
            font-size: 24px;
            color: white;
            height: 3.5em;
            width: 15em;
        }
        .button1 {
            margin-right: 30px;
            color: $btn-color-text;
            background-color: $button-color;
            font-weight: bold;
            .line1 {
                font-size: smaller;
                color: white;
                display: block;
            }
            .line2 {
                color: white;
                display: block;
            }
        }
    }
    .button-contact {
        background-color: $main-color;
    }
    .btn-layout-sm{
        margin: 1em 1em 1em 0em;
        padding: 1em 3em;
    }
    .btn1-style {
        height: 4em;
        color: $btn-color-text;
        background-color: $button-color;
        font-weight: bold;
        .line1 {
            font-size: smaller;
            color: white;
            display: block;
        }
        .line2 {
            color: white;
            display: block;
        }
    }
    .btn2-style {
        color: $text-color;
        background-color: $main-color;
        font-weight: bold;
    }
    .btn3-style {
        color: $btn-color-text;
        background-color: $button-color;
        font-weight: bold;
        .line1 {
            color: white;
        }

    }
    .btn4-style {
        color: $btn-color-text;
        background-color: $button-color;
        font-weight: normal;
		font-size: 11px;
        .line1 {
            color: white;
        }

    }
    td {
        padding: 5px;
    }
    .face_icon {
        height:3em;
        margin: 0.2em;
        margin-top: -25px
    }
    .text-form {
        width: 25em;
    }
	.bg-label {
		background-color: #F2F2F2 !important;
	}
	#table-inquiry {
		text-align: left;
		tr {
			border: solid 1px;
		}
		td {
			padding: 10px;
			background-color: #fff;
		}
	}
	#form-inquiry {
		#inquiry-buttons {
		    margin-bottom: 25px;
            #button-inquiry {
                margin-top: 25px;
                color: $text-color;
                background-color: #F2F2F2;
                font-weight: bold;
            }
			#button-send {
				margin-top: 25px;
				font-size: 24px;
				background-color: $button-color;
			}
			#button-send:hover {
				color: #fff;
			}
			.tel {
				margin-top: 25px;
				.line1 {
					font-size: 12px;
				}
				.line2 {
					font-size: 24px;
					color: $button-color;
					font-weight: 900;
					line-height: 1;
				}
				.line3 {
					font-size: 12px;
				}
			}
		}
	}
	
	.page3-content {
		padding: 20px 0;
		margin: 20px;
		.page3-box {
			padding: 20px 0;
			margin: 20px;
			background-color: #fff;
			.page3-line1 {
				font-size: 24px;
				color: $button-color;
				font-weight: 900;
			}
			.page3-line2 {
				margin: 10px 0;
			}
		}
		.page3-back {
			margin: 20px;
		}
		.disclaimer {
			margin: 20px;
		}
	}
	
	
    // Larger than medium
    @media (min-width: 992px) {
        .ipanel {
            &.panel-left {
                padding-right: 15px;
            }
            &.panel-right {
                padding-left: 15px;
            }

        }

    }
    @media (max-width: 749px) {
        .face_icon {
            height: 63px;
            margin-top: 0;
        }
        .face-order img {
            width: 60px;   
        }
        .text-form {
        width: 90%;
        }
        .buttons button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
// Footer
.footer-row {
    background-color: #4D4D4D;
}
footer .zweispace-logo {
    margin-top: 15px;
    margin-right: 20px;
}
footer a {
    color: white;
    font-size: 12px;
}
footer .copyright {
    padding-top: 15px;
    margin-right: 15px;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    color: white;
    font-size: 12px;
}
// Medium devices (sol-md)
@media (max-width: 991px) {
    .ipanel {
        .left-panel {
            float: none;
        }
        .right-panel {
            float: none;
        }
    }
}
// Mobile devices (col-sm)
@media (max-width: 749px ) {
    .ipanel {
        .panel-wrap {
            width: 95%;
            margin: 0 auto;
        }
        .top-image img {
            width: 100%;
        }
    }
    footer {
        .logo-link {
            margin-top: 15px;
            margin-bottom: 15px;
            float: none !important;
            display: block;
            width: 100%;
        }
    }
}
html,body {
    height:100%;
}
.text-change {
    font-size: 16px;
}
.space-top {
    margin-top: 20px
}

#progressbar {
    text-align: right;
    margin: 20px;
    width: 150px;
    height: 150px;
    position: relative;
    font-size: xx-large;
}